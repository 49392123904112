import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// import required modules
import { Autoplay } from 'swiper'
import { useViewport } from '@/hooks/useViewport'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

const Services = () => {
  const { isTablet, isDesktop, isWidescreen } = useViewport()
  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            name
            id
            description
            icon
            slug
          }
        }
      }
    }
  `)

  return (
    <section className="section-padding section thm-bg-light">
      <div className="container">
        <Swiper
          slidesPerView={(isWidescreen && 3) || (isDesktop && 2) || (isTablet && 1) || 1}
          loop={true}
          autoplay={{
            delay: 2500,
          }}
          modules={[Autoplay]}
          className="row service_slider"
        >
          {data.allServicesJson.edges.map(({ node }) => (
            <SwiperSlide key={node.id}>
              {/* <div
                
                className="col-lg-12 slide_item"
              >
                <Link to={node.slug} className="service_box">
                  <i className={node.icon}></i>
                  <h5 className="title">{node.name}</h5>
                  <p className="text">{node.description}</p>
                </Link>
              </div> */}
              <div className="col-lg-12 slide_item">
                <div className="service-box-new">
                  {/* <i className={node.icon}></i>
                  <h5 className="title">{node.name}</h5>
                  <p className="text">{node.description}</p> */}
                  {/* <div className='relative'> */}
                  <img src={node.icon} alt="img" />
                  {/* </div> */}
                  <div className="content-card-service">
                    <h4 className="title">{node.name}</h4>
                    <Link className="hide" to={`/services/${node.slug}`}>
                      VIEW MORE
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}

export default Services
