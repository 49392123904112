import React from 'react'
import Layout from '@/components/Layout'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import SubHeader from '@/components/Common/SubHeader'
import Services from '@/components/Services'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'

const Ser = () => {
  return (
    <Layout>
      <SEO title="Services" canonical={useLocation().host} />
      <Header />
      <SubHeader title="Services" />
      <Services/>
      <Footer />
    </Layout>
  )
}

export default Ser
